import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import bannerBG from "../images/CustomOrderingWebsite/banner.png";
import gradientBg from "../images/CustomOrderingWebsite/gradient-pink-bg.png";
import iconAdv1 from "../images/CustomOrderingWebsite/icon-adv-1.png";
import iconAdv2 from "../images/CustomOrderingWebsite/icon-adv-2.png";
import iconAdv3 from "../images/CustomOrderingWebsite/icon-adv-3.png";
import Banner from "../components/banner/banner";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import News from '../components/news/news';
import Seo from "../components/seo";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";
import TrialButton from "../components/trialButton/trialButton";

// markup
const CustomOrderingWebsitePage = ({ location, data }) => {
  const scrollToForm = "#";

  const topProps = {
    layout: 2,
    topImage: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="picca"
        src="../images/CustomOrderingWebsite/picca.png"
      />
    ),
    title: (
      <>
        <span>Commission Free Ordering Website</span>
      </>
    ),
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="orders-online"
        // src="../images/CustomOrderingWebsite/orders-online.png"
        alt="orders-online"
        src="../images/CustomOrderingWebsite/Orders-1.png"
        className="mw-100"
      />
    ),
    imageSize: "big",
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: "Direct Ordering",
      description: `Cut out the middlemen and give your customers an easy way to order directly from
            you without the commission!`,
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: "Personalized Website",
      description: `Create your very own ordering website in no time with just your menu.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reliable Delivery",
      description: `Offer delivery on all website orders with in-house staff or through our delivery partners.`,
    },
  ];

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/CustomOrderingWebsite/restaurant-owner.png"
          alt="restaurant owner"
          className="mw-100 w-100"
        />
      ),
      text: `The team is just amazing, whenever we have a question just one call and they
        fix the issue. I’ve been very happy since I started using orders.co, because my job
        has become a lot easier.`,
      author: "Marthin Ken",
      to: '/customer-stories-pops-artisanal-creamery/',
      position: "Pops Creamery Owner",
    },
  ];

  const testimonialsProps = {
    layout: 3,
    items: testimonials,
    titleImg: (
      <StaticImage
        placeholder="none"
        loading="eager"
        src="../images/CustomOrderingWebsite/smile.png"
        alt="smile"
      />
    ),
    title: "Hear From Our Customers",
    tag: "",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Create a Stronger Bond with Customers",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p>
        Studies show that customers prefer to order direct from a restaurant.
        Give them that option and keep them coming back with loyalty rewards!
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="customers"
        src="../images/CustomOrderingWebsite/customers.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-big",
    title: "Turn Website Visitors Into Clients",
    titleStyle: "smaller",
    imagePosition: "right",
    description: (
      <p>
        Customers like to research their food before ordering and having an easy
        ordering option built into your website will create more business.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="c-viche client"
        src="../images/CustomOrderingWebsite/c-viche-jessica.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "Streamline Operations",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p>
        All your orders and menu management built into one place means less time
        wasted and more revenue earned. Why pay someone else to manage your
        website when you can do it with ease!
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="menu management"
        // src="../images/CustomOrderingWebsite/menu-management.png"
        alt="tablet"
        src="../images/CustomOrderingWebsite/tablet.png"
      />
    ),
  };

  const bannerProps = {
    layout: 1,
    bg: bannerBG,
    title: (
      <>
        Ready to Build
        <br className="d-md-none" /> Your Ordering
        <br className="d-md-none" /> Website?
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const questions = [
    {
      question: `Do you also provide delivery services?`,
      answer: `Orders.co allows for both in-house and UberEats fulfillment on website orders.`,
    },
    {
      question: `Can you help me create a menu QR code?`,
      answer: `Yes, submit a request to our 24/7 support team and a Menu QR code will be generated
                absolutely free of charge.`,
    },
    {
      question: `Will my ordering website rank on Google?`,
      answer: `Restaurant partners can work with your optimization team to improve your Google
                ranking and gain exposure for your business.`,
    },
    {
      question: `How does my menu become a website?`,
      answer: `Once your menu has been created in the Orders.co Master Menu it is linked to your
                website and can be live in moments.`,
    },
    {
      question: `How does Orders.co process credit card payments?`,
      answer: `Currently, Orders.co is partnered with Stripe for all online purchase processing.
                Restaurant partners looking to setup an ordering website are required to have or create
                a Stripe account.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Custom ordering website"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <div className="bg-desktop" style={{ background: "#FFECDE" }}>
          <Top {...topProps} />
        </div>
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-80px",
            paddingTop: "40px",
          }}
        >
          <Features items={features} />
        </div>
        <div
          className="bg-desktop"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
            paddingBottom: `50px`,
            marginBottom: `50px`,
          }}
        >
          <Testimonials {...testimonialsProps} />
          <Title layout={"bolder"}>
            <span style={{ color: "#373636" }}>Deliver to </span>
            <span className="orange-text">Customers Directly </span>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
          <div className="d-flex justify-content-center">
            <TrialButton
              scrollToForm={scrollToForm}
              classes={["trial-button bg-orange cursor-pointer"]}
            >
              GET STARTED FOR FREE
            </TrialButton>
          </div>
        </div>
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <News items={data.allWpPost} />
          <Banner {...bannerProps} />
          <Faq items={questions} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "custom-ordering-website" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "custom-ordering-website"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomOrderingWebsitePage;
